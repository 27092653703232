<template>
  <AdminPage>
    
      <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <div 
            class="field has-addons"
          >
            <p class="control">
              <span class="select">
                <select>
                  <option>All timeframes</option>
                  <!-- <option>Last year</option>
                  <option>Last period</option> -->
                </select>
              </span>
            </p>
            <p class="control">
              <span class="select">
                <select>
                  <option>All transaction types</option>
                  <!-- <option>credit purchases</option>
                  <option>all claims</option>
                  <option>in-app claims</option>
                  <option>QR claims</option> -->
                </select>
              </span>
            </p>
            <p class="control">
              <span class="select">
                <select>
                  <option>All locations</option>
                  <!-- <option>Burlington, VT</option>
                  <option>..</option> -->
                </select>
              </span>
            </p>
          </div>
        </div>
      </div>
      <!-- Right side -->
      <div class="level-right">
        <p class="level-item"><strong>{{ transactionsCount }}</strong></p>
      </div>
    </nav>

    <b-table
      :data="transactions"
      :loading="loading"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      default-sort="date"
      default-sort-direction="desc"
    >
      <b-table-column
        field="date"
        label="Date"
        sortable
        v-slot="props"
      >
        {{ new Date(props.row.date).toLocaleDateString() }} {{ new Date(props.row.date).toLocaleTimeString() }}
      </b-table-column>

      <b-table-column
        field=""
        label="Status"
        sortable
        v-slot="props"
      >
        {{ props.row.status }}
      </b-table-column>

      <b-table-column
        field="business"
        label="Business"
        sortable
        v-slot="props"
      >
        {{ props.row.business }}
      </b-table-column>

      <b-table-column
        field="description"
        label="Description"
        v-slot="props"
      >
        {{ props.row.description }}
      </b-table-column>

      <b-table-column
        field="category"
        label="Claim Type"
        sortable
        v-slot="props"
      >
        {{ props.row.category }}
      </b-table-column>

      <b-table-column
        field="approved"
        label="Approved By"
        sortable
        v-slot="props"
      >
        {{ props.row.approved }}
      </b-table-column>

      <b-table-column
        field="amount"
        label="Amount"
        sortable
        v-slot="props"
      >
        {{ USD(props.row.amount).format() }}
      </b-table-column>

      <b-table-column
        field="ll_fee"
        label="LL Fee"
        sortable
        v-slot="props"
      >
        {{ USD(props.row.ll_fee).format() }}
      </b-table-column>

      <b-table-column
        field="cc_fee"
        label="CC Fee"
        sortable
        v-slot="props"
      >
        {{ USD(props.row.cc_fee).format() }}
      </b-table-column>

      <b-table-column
        field="subtotal"
        label="Total"
        sortable
        v-slot="props"
      >
        {{ USD(props.row.subtotal).format() }}
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No transactions</div>
      </template>
    </b-table>
  </AdminPage>
</template>

<script>
import AdminPage from "@/components/AdminPage.vue";
import api from "@/service/api.js";

export default {
  components: {
    AdminPage
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    transactionsCount: function() {
      return this.transactions.length
    }
  },
  data() {
    return {
      loading: false,
      transactions: []
    }
  },
  mounted() {
    this.loading = true

    api.getAdminTransactions(this.currentUser.slug)
      .then(resp => {
        this.transactions = resp.data
      })
      .catch(error => {
        this.error = error.data
      })
      .finally(() => {
        this.loading = false
      })
  },
}
</script>
